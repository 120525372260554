// Themse, change filname: [blank], -coy, -dark, -funky, -okaidia, -solarizedlight, -tomorrow, -twilight
require("prismjs/themes/prism-okaidia.css");
require("./src/styles/copy-button.css");

exports.onClientEntry = () => {
  window.gatsbyRemarkCopyToClipboard = (button) => {
    const codeBlock = button.parentElement.nextElementSibling;
        
    if (codeBlock && codeBlock.className === 'gatsby-highlight') {
      const code = codeBlock.innerText;
      
      navigator.clipboard.writeText(code).then(() => {
        button.textContent = 'Copied!';
        
        setTimeout(() => {
          button.textContent = 'Copy';
        }, 2000);
      });
    }
  };
  
    document.addEventListener('keydown', (event) => {
      if ((event.key === 'Enter' || event.key === ' ') && event.target.classList.contains('gatsby-remark-prismjs-copy-button')) {
          event.preventDefault();
          window.gatsbyRemarkCopyToClipboard(event.target);
      }
    });
  };